
import Vue from "vue";

export default Vue.extend<{}, {}, Computed, Props>({
  computed: {
    style() {
      return {
        padding: `${this.padding}px`,
        borderRadius: `${this.radius}px`,
      };
    },
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    padding: {
      type: Number,
      default: 4,
    },
    radius: {
      type: Number,
      default: 5,
    },
    changeText: {
      type: Function,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 20,
    },
    text: {
      type: String,
      default: "",
    },
  },
});

interface Computed {
  style: { [key: string]: string };
}

interface Props {
  type: string;
  text: string;
  placeholder: string;
  maxLength: number;
  radius: number;
  padding: number;
  changeText: (e: InputEvent) => void;
}
